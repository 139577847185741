<template>
  <div class="timer-max-w-[235px]">
    <div class="timer-font-bold timer-text-[#777482]">Opening Hours</div>
    <div class="timer-mt-1">
      <div class="timer-font-normal timer-text-[#777482]">
        <span class="timer-font-medium">Mon - Fri:</span> {{ openingHourDayWithTimeZone }} -
        {{ closingHourDayWithTimeZone }}
      </div>
      <div class="timer-font-normal timer-text-[#777482]">
        <span class="timer-font-medium timer-mr-[3px]">Saturday:</span> {{ openingHourWeekWithTimeZone }} -
        {{ closingHourWeekWithTimeZone }}
      </div>
    </div>
    <div class="timer-text-xs timer-mt-1">Hours shown based on your timezone.</div>
    <div class="timer-block timer-h-[1px] timer-w-full timer-bg-gray-200 timer-mt-2"></div>
    <div class="timer-text-base timer-leading-5 timer-mt-2" v-html="officeHoursCount"></div>
  </div>
</template>

<script>
import {DateTime} from "luxon";

export default {
  data() {
    return {
      todayClosedTime: "",
      officeHoursCount: "",
      userTimeZone: "",
      utcString: "",
      currentTime: "",
      openingHourDayWithTimeZone: "",
      closingHourDayWithTimeZone: "",
      openingHourWeekWithTimeZone: "",
      closingHourWeekWithTimeZone: "",
      timeZoneWorkHours: [
        {day: "monday", open: "", closed: ""},
        {day: "tuesday", open: "", closed: ""},
        {day: "wednesday", open: "", closed: ""},
        {day: "thursday", open: "", closed: ""},
        {day: "friday", open: "", closed: ""},
        {day: "saturday", open: "", closed: ""},
        {day: "sunday", open: "", closed: ""},
      ],
    };
  },
  mounted() {
    this.setUserOffset();
    this.calculateTodayEndTime();
    setInterval(this.calculateTodayEndTime, 60000); // Refreshes every minute
  },

  methods: {
    setUserOffset() {
      const netherlandsTimeZone = "Europe/Amsterdam";
      const userTimeZone = DateTime.local().zoneName;
      this.userTimeZone = userTimeZone;

      const utcOffset = DateTime.local().offset / 60;
      const sign = utcOffset >= 0 ? "+" : "-";
      const hours = Math.abs(Math.floor(utcOffset));
      const minutes = Math.abs((utcOffset % 1) * 60);
      this.utcString = `UTC ${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;

      const openingHourWeekDay = "04:00";
      const closingHourWeekDay = "18:00";
      const openingHourWeekEnd = "08:00";
      const closingHourWeekEnd = "16:00";

      this.openingHourDayWithTimeZone = this.convertTimeToUserTimeZone(openingHourWeekDay, netherlandsTimeZone);
      this.closingHourDayWithTimeZone = this.convertTimeToUserTimeZone(closingHourWeekDay, netherlandsTimeZone);
      this.openingHourWeekWithTimeZone = this.convertTimeToUserTimeZone(openingHourWeekEnd, netherlandsTimeZone);
      this.closingHourWeekWithTimeZone = this.convertTimeToUserTimeZone(closingHourWeekEnd, netherlandsTimeZone);

      this.timeZoneWorkHours.forEach((day, index) => {
        if (index < 5) {
          day.open = this.openingHourDayWithTimeZone;
          day.closed = this.closingHourDayWithTimeZone;
        } else {
          day.open = this.openingHourWeekWithTimeZone;
          day.closed = this.closingHourWeekWithTimeZone;
        }
      });

      // For Sunday (day off)
      this.timeZoneWorkHours[6].open = "dayOFF";
      this.timeZoneWorkHours[6].closed = "dayOFF";

      this.$emit("time-zone", {
        openingHDay: this.openingHourDayWithTimeZone,
        closingHDay: this.closingHourDayWithTimeZone,
        openingHWeek: this.openingHourWeekWithTimeZone,
        closingHWeek: this.closingHourWeekWithTimeZone,
      });
    },
    convertTimeToUserTimeZone(timeString, timeZone) {
      const [hours, minutes] = timeString.split(":").map(Number);
      const netherlandsTime = DateTime.fromObject({hour: hours, minute: minutes}, {zone: timeZone});
      const userTime = netherlandsTime.setZone(DateTime.local().zoneName);
      return userTime.toFormat("HH:mm");
    },
    calculateTodayEndTime() {
      const netherlandsTimeZone = "Europe/Amsterdam";
      const currentTime = DateTime.local().setZone(netherlandsTimeZone);
      this.currentTime = currentTime.toFormat("HH:mm:ss");

      const todayName = currentTime.toFormat("cccc").toLowerCase();
      const openingHourWeekDay = "04:00";
      const closingHourWeekDay = "18:00";
      const openingHourWeekEnd = "08:00";
      const closingHourWeekEnd = "16:00";

      let todayOpenTime, todayClosedTime;

      if (todayName === "saturday" || todayName === "sunday") {
        todayOpenTime = DateTime.fromFormat(openingHourWeekEnd, "HH:mm", {zone: netherlandsTimeZone});
        todayClosedTime = DateTime.fromFormat(closingHourWeekEnd, "HH:mm", {zone: netherlandsTimeZone});
      } else {
        todayOpenTime = DateTime.fromFormat(openingHourWeekDay, "HH:mm", {zone: netherlandsTimeZone});
        todayClosedTime = DateTime.fromFormat(closingHourWeekDay, "HH:mm", {zone: netherlandsTimeZone});
      }

      const now = DateTime.local().setZone(netherlandsTimeZone);

      // Calculate remaining time until opening and closing
      const timeUntilOpen = todayOpenTime.diff(now, ["hours", "minutes"]).toObject();
      const timeUntilClose = todayClosedTime.diff(now, ["hours", "minutes"]).toObject();

      if (now < todayOpenTime) {
        this.officeHoursCount = `We open in<span class="timer-text-[#ff4b00] timer-font-semibold"> ${Math.floor(timeUntilOpen.hours)} h ${Math.ceil(timeUntilOpen.minutes)} min</span>`;
      } else if (now >= todayOpenTime && now < todayClosedTime) {
        if (Math.ceil(timeUntilClose.minutes) >= 60) {
          this.officeHoursCount = `We close in<span class="timer-text-[#ff4b00] timer-font-semibold"> ${Math.floor(timeUntilClose.hours)} h</span>`;
        } else {
          this.officeHoursCount = `We close in<span class="timer-text-[#ff4b00] timer-font-semibold"> ${Math.floor(timeUntilClose.hours)} h ${Math.ceil(timeUntilClose.minutes)} min</span>`;
        }
      } else {
        this.officeHoursCount = '<span class="timer-text-[#ff4b00] timer-font-semibold">Our Office Is Closed</span>';
      }
    },
  },
};
</script>
